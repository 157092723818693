import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import RefreshIcon from '@material-ui/icons/Refresh';
import TableLoader from '@root/containers/loader';
import { addUserLog, getUserLogs, IGetUserLogs } from '@root/services/UserLogSvc';
import format from 'date-fns/format';
import range from 'lodash/range';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { IMessage } from '../App';
import { RefreshButton, StyledTableContainer } from './TradesLogs.styles';

const FETCH_SIZE = 20;

const LogsContainer: React.FC<{}> = () => {
  const tableRef = useRef<HTMLDivElement | null>(null);
  const [userLogs, setUserLogs] = useState<IMessage[]>([]);
  const [loading, setLoading] = useState<boolean>();
  const [previousRequest, setPreviousRequest] = useState<IGetUserLogs>();

  const onReset = useCallback(async () => {
    setUserLogs(() => []);
    setLoading(true);
    const request = {
      limit: FETCH_SIZE,
      offset: 0,
    };
    setPreviousRequest(() => request);
    try {
      const logs = await getUserLogs(request);
      setUserLogs(() => [...(logs || [])]);
    } catch (e) {
      addUserLog({
        message: (e as Error).message,
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [setUserLogs, setLoading]);

  const onLoadMore = async () => {
    if (
      previousRequest &&
      userLogs &&
      userLogs?.length > FETCH_SIZE / 2
    ) {
      setLoading(true);
      const request = {
        limit: previousRequest?.limit,
        offset: previousRequest?.offset! + previousRequest?.limit!,
      };
      setPreviousRequest(() => request);
      try {
        const newData = await getUserLogs(request);
        setUserLogs((oldData) => [...(oldData || []), ...newData]);
      } catch (e) {
        addUserLog({
          message: (e as Error).message,
          type: "error",
        });
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    onReset?.();
  }, [onReset]);

  const onResetClick = () => {
    tableRef?.current?.scrollTo?.({
      top: 0,
      behavior: 'smooth',
    });
    onReset?.();
  };

  return (
    <>
      <RefreshButton color="primary" disabled={loading} onClick={onResetClick}>
        <RefreshIcon />
      </RefreshButton>
      <StyledTableContainer ref={tableRef}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Log</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userLogs?.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {format(row.date, 'MMM do yyyy HH:mm aa')}
                </TableCell>
                <TableCell title={row.message}>
                  {row.message}</TableCell>
              </TableRow>
            ))}
            {loading && range(10).map((val) => (
              <TableRow key={val}>
                <TableCell>
                  <TableLoader margin={0} />
                </TableCell>
                <TableCell>
                  <TableLoader margin={0} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Waypoint onEnter={onLoadMore} />
      </StyledTableContainer>
    </>
  );
};

export default LogsContainer;
