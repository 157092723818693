type TCallback = () => void;
const wsOpenSubscription: TCallback[] = [];
const wsCloseSubscription: TCallback[] = [];

/**
 * This method wont be called first time after connection.
 * This is meant to be called for subsequent reconnection
 * @param callback 
 * @returns 
 */
export const subscribeToWSOpen = (callback: TCallback) =>
  wsOpenSubscription.push(callback);

export const subscribeToWSClose = (callback: TCallback) =>
  wsCloseSubscription.push(callback);

export const callOpenCallbacks = () =>
  wsOpenSubscription?.forEach?.((e) => e?.());
export const callCloseCallbacks = () =>
  wsCloseSubscription?.forEach?.((e) => e?.());
