import * as brokerEngineInterface from "@root/services/broker/BrokerSvc";
import {
  ITradeHistoryData,
  ITradeHistoryRequest,
} from "@root/services/broker/BrokerSvc.types";
import { addUserLog } from "@root/services/UserLogSvc";
import { useCallback, useState } from "react";
import { useAppContext } from "../App.context";

const FETCH_SIZE = 50;

type TradeHistoryData = Omit<ITradeHistoryData, "symbol"> & {
  assetName: string;
};

const useTradeHistory = () => {
  const [tradeHistory, setTradeHistory] = useState<TradeHistoryData[]>();
  const [loading, setLoading] = useState<boolean>();
  const { userData } = useAppContext();
  const [previousRequest, setPreviousRequest] =
    useState<ITradeHistoryRequest>();

  const onLoadMore = async () => {
    if (
      userData?.isLoggedIn &&
      previousRequest &&
      tradeHistory &&
      tradeHistory?.length > FETCH_SIZE / 2
    ) {
      setLoading(true);
      const request = {
        dateTo: previousRequest?.dateTo!,
        limit: previousRequest?.limit,
        offset: previousRequest?.offset! + previousRequest?.limit!,
      };

      try {
        const [symbolsResponse, tradeHistoryResponse] = await Promise.all([
          brokerEngineInterface.getAllSymbols?.(),
          brokerEngineInterface.getTradeHistory?.(request),
        ]);
        const newData =
          tradeHistoryResponse?.map?.((e) => ({
            ...e,
            assetName: symbolsResponse?.find?.(
              (f) => f?.symbol && f?.symbol === e?.symbol
            )?.fullName!,
          })) || [];
        setTradeHistory((oldData) => [...(oldData || []), ...newData]);
        setPreviousRequest(() => request);
      } catch (e) {
        addUserLog({
          message: (e as Error).message,
          type: "error",
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const onReset = useCallback(async () => {
    setTradeHistory(() => []);
    if (userData?.isLoggedIn) {
      setLoading(true);
      const request = {
        dateTo: new Date(),
        limit: FETCH_SIZE,
        offset: 0,
      };
      setPreviousRequest(() => request);

      try {
        const [symbolsResponse, tradeHistoryResponse] = await Promise.all([
          brokerEngineInterface.getAllSymbols?.(),
          brokerEngineInterface.getTradeHistory?.(request),
        ]);
        const newData =
          tradeHistoryResponse?.map?.((e) => ({
            ...e,
            assetName: symbolsResponse?.find?.((f) => f?.symbol === e?.symbol)
              ?.fullName!,
          })) || [];
        setTradeHistory(() => [...newData]);
      } catch (e) {
        addUserLog({
          message: (e as Error).message,
          type: "error",
        });
      } finally {
        setLoading(false);
      }
    }
  }, [userData, setLoading, setPreviousRequest]);

  return {
    tradeHistory,
    loading,
    onLoadMore,
    onReset,
  };
};

export default useTradeHistory;
