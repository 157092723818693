import logLevel from 'loglevel';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App.container';
import './Logger';
import reportWebVitals from './reportWebVitals';

const logger = logLevel.getLogger('index.tsx');

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(logger.debug);
