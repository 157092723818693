import getAllSymbolsImport from "./GetAllSymbols";
import getHistoricalDataImport from "./GetHistoricalData";
import getServerTimeImport from "./GetServerTime";
import getSupportedResolutionsImport from "./GetSupportedRes";
import getTradeHistoryImport from "./GetTradeHistory";
import * as subscribeOHLC from "./SubscribeOHLC";
import * as SubscribeToWSCon from "./SubscribeToWSCon";
import loginImport from './Login';
import logoutImport from './Logout';
import getTradingTimesImport from './GetTradingTimes';
import buyImport from './BuyContract';

export const subscribeToWSOpen = SubscribeToWSCon.subscribeToWSOpen;
export const subscribeToWSClose = SubscribeToWSCon.subscribeToWSClose;
export const getHistoricalData = getHistoricalDataImport;
export const getSupportedResolutions = getSupportedResolutionsImport;
export const subscribeToOHLC = subscribeOHLC.subscribeToOHLC;
export const unsubscribeFromOHLC = subscribeOHLC.unsubscribeFromOHLC;
export const getAllSymbols = getAllSymbolsImport;
export const getServerTime = getServerTimeImport;
export const getTradeHistory = getTradeHistoryImport;
export const login = loginImport;
export const logout = logoutImport;
export const getTradingTimes = getTradingTimesImport;
export const buyContract = buyImport;
