import fromUnixTime from "date-fns/fromUnixTime";
import { IBuyContractRequest } from "../BrokerSvc.types";
import { IOnMessage } from "./Deriv";
import waitForReadyConnection from "./WaitForConnection";

const buyContract = (request: IBuyContractRequest) =>
  waitForReadyConnection().then((wsp) =>
    wsp
      ?.sendRequest({
        buy: 1,
        // TODO: use slippage - ask user how much to vary
        price: Number.MAX_SAFE_INTEGER,
        parameters: {
          basis: "stake",
          contract_type: request.contractType,
          currency: request.currency,
          symbol: request.symbol,
          duration: request.duration,
          duration_unit: request.durationUnit,
          amount: request.amount,
        },
      })
      .then((message: IOnMessage) => {
        if (message.error) {
          throw new Error(message?.error?.message);
        } else {
          return {
            transactionId: message.buy.transaction_id,
            buyPrice: message.buy.buy_price,
            purchaseTime: fromUnixTime(message.buy.purchase_time),
            shotCode: message.buy.shortcode,
            longCode: message.buy.longcode,
          };
        }
      })
  );

export default buyContract;
