import fromatDate from "date-fns/format";
import isWeekend from 'date-fns/isWeekend';
import nextMonday from 'date-fns/nextMonday';
import getUnixTime from 'date-fns/getUnixTime'
import { TDay } from "../BrokerSvc.types";
import { IOnMessage } from "./Deriv";
import waitForReadyConnection from "./WaitForConnection";

const getWorkingDate = () => {
  let date = getUnixTime(Date.now()) * 1000;
  while (isWeekend(date)) {
    date = nextMonday(date).valueOf();
  }
  return date;
};

const getTradingTimes = () =>
  waitForReadyConnection().then((wsp) =>
    wsp
      ?.sendRequest({
        trading_times: fromatDate(getWorkingDate(), "yyyy-MM-dd"),
      })
      .then((message: IOnMessage) => {
        if (message.error) {
          throw new Error(message?.error?.message);
        } else {
          const times: {
            symbol: string;
            openTime: string;
            closeTime: string;
            tradingDays: TDay[];
          }[] = [];
          message.trading_times?.markets?.forEach((m) => {
            m?.submarkets?.forEach((sm) => {
              sm?.symbols?.forEach((s) => {
                times.push({
                  symbol: s.symbol,
                  openTime: s.times?.open?.[0],
                  closeTime: s.times?.close?.[0],
                  tradingDays: s.trading_days,
                });
              });
            });
          });
          return times;
        }
      })
  );

export default getTradingTimes;
