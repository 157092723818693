import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  DateTimePicker, MuiPickersUtilsProvider
} from '@material-ui/pickers';
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import PlayArrowOutlinedIcon from '@material-ui/icons/PlayArrowOutlined';
import PausePresentationOutlinedIcon from '@material-ui/icons/PausePresentationOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { AutocompleteRoot, SelectionContainerRoot } from './Backtest.styles';

const files = Array(20).fill(null).map((f, index) => ({
  name: `File${index}.ts`,
}));

const BacktestSelectionContainer: React.FC<{}> = () => {
  const [backtestingStarted, setBacktestingStarted] = useState(false);
  const onToggleStartStop = () => setBacktestingStarted(old => !old);
  return (
    <SelectionContainerRoot>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justifyContent="space-around">
          <AutocompleteRoot>
            <Autocomplete
              options={files}
              getOptionLabel={(option) => option.name}
              value={files?.[0]}
              renderInput={(params) => <TextField
                {...params}
                label="Select strategy"
                variant="standard"
                size="small"
                fullWidth
              />}
              size="small"
            />
          </AutocompleteRoot>
          <DateTimePicker
            variant="inline"
            margin="none"
            label="From"
            value={Date.now()}
            onChange={() => { }}
          />
          <Tooltip title={backtestingStarted ? 'Stop' : 'Start'}>
            <Button size="small" onClick={onToggleStartStop} color={backtestingStarted ? 'secondary' : 'primary'}>
              {backtestingStarted ? <PausePresentationOutlinedIcon /> : <PlayArrowOutlinedIcon />}
            </Button>
          </Tooltip>
        </Grid>
      </MuiPickersUtilsProvider>
    </SelectionContainerRoot>
  );
};

export default BacktestSelectionContainer;
