import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

export const FileExplorerRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-right: 1px solid #E0E0E0;
  height: calc(100vh - 120px);
  overflow: auto;
  margin-right: 5px;
  &::-webkit-scrollbar {
    width: .5em;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(5, 81, 100, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
`;

export const ButtonRoot = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FileExplorerRowComponentRoot = styled(Paper)`
  margin-right: 10px;
`;

export const FileItemsRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
`;

export const StyledFileName = styled(Typography)`
  && {
    cursor: pointer;
  }
`;

