import useMediaQuery from '@material-ui/core/useMediaQuery';
import { AppContextProvider, Tabs, useAppContext, useAppContextModel } from '@root/containers/App.context';
import BacktestContainer from '@root/containers/backtest';
import ChartContainer from '@root/containers/chart';
import HeaderContainer from '@root/containers/header/Header.container';
import StrategyContainer from '@root/containers/strategy';
import ToastContainer from '@root/containers/toast';
import TradesLogsContainer from '@root/containers/trades-logs';
import { StyledContainer, StyledNoContentImg, StyledSmallResMsg } from './App.styles';
import FranceImage from './France-day.svg';

const AppContainer = () => {
  const { selectedTab } = useAppContext();
  return (
    <>
      <HeaderContainer />
      <ToastContainer />
      <ChartContainer render={selectedTab === Tabs.Chart} />
      <StrategyContainer render={selectedTab === Tabs.Strategy} />
      <BacktestContainer render={selectedTab === Tabs.Backtest} />
      <TradesLogsContainer render={selectedTab === Tabs.TradesLogs} />
    </>
  );
};

const App = () => {
  const appContextModel = useAppContextModel();
  const matches = useMediaQuery('(min-width:1310px)');
  return (
    <StyledContainer>
      {!matches && <>
        <StyledNoContentImg src={FranceImage} />
        <StyledSmallResMsg>Small screen resolutions are not supported</StyledSmallResMsg>
      </>}
      {matches && <AppContextProvider value={appContextModel}>
        <AppContainer />
      </AppContextProvider>}
    </StyledContainer>
  );
};

export default App;
