import styled from 'styled-components';

export const StyledContainer = styled.div`
  margin: auto;
  border-radius: 8px;
  background-color: white;
  padding: 10px;
  @media only screen and (max-width: 1310px) {
    height: calc(100vh - 40px);
  }
`;

export const StyledNoContentImg = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: initial;
  @media only screen and (min-width: 1310px) {
    display: none;
  }
`;

export const StyledSmallResMsg = styled.span`
  position: absolute;
  left: 10%;
  top: 45%;
  font-family: monospace;
  font-size: xx-large;
  font-weight: 900;
  display: initial;
  @media only screen and (min-width: 1310px) {
    display: none;
  }
`;
