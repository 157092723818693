import * as brokerEngineInterface from "@root/services/broker/BrokerSvc";
import {
  TContractType,
  TDurationUnit,
} from "@root/services/broker/BrokerSvc.types";
import { addUserLog } from "@root/services/UserLogSvc";
import getUnixTime from "date-fns/getUnixTime";
import React, { useCallback, useState } from "react";
import { useAppContext } from "../App.context";

const useChartTrade = () => {
  const { userData } = useAppContext();
  const [tradeAmount, setTradeAmount] = useState<number>(1.0);
  const [higherOrderInProgress, setHigherOrderInPrgress] = useState<boolean>();
  const [lowerOrderInProgress, setLowerOrderInPrgress] = useState<boolean>();
  const [duration, setDuration] = useState<number>(1);
  const [durationUnit, setDurationUnit] = useState<TDurationUnit>("m");
  const [durationAnchor, setDurationAnchor] =
    React.useState<null | HTMLElement>(null);

  const handleDurationClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDurationAnchor(event.currentTarget);
  };

  const handleDurationMenuItemClick = (dUnit: TDurationUnit) => () => {
    setDurationUnit(() => dUnit);
    handleDurationMenuClose();
  };

  const handleDurationMenuClose = () => {
    setDurationAnchor(null);
  };

  const onChangeTradeAmount = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setTradeAmount((oldValue) => +event.target?.value || oldValue);
    },
    [setTradeAmount]
  );

  const onChangeDurationAmount = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDuration((oldValue) => +event.target?.value | 0 || oldValue);
    },
    [setDuration]
  );

  const placeOrder = async (contractType: TContractType) => {
    const currency = userData?.accounts?.find((f) => f.selected)?.currency;

    const symbolName = window.tvWidget?.activeChart?.().symbol?.();
    const allSymbols = await brokerEngineInterface.getAllSymbols();
    const symbol = allSymbols.find((f) => f.fullName === symbolName)?.symbol;

    if (!currency) {
      throw new Error("Unknown account currency");
    }

    if (!symbol) {
      throw new Error("Unknown symbol");
    }

    const response = await brokerEngineInterface.buyContract({
      amount: tradeAmount,
      contractType,
      currency,
      duration,
      durationUnit,
      symbol,
    });
    addUserLog({
      message: `Order filled, TransactionId: ${response.transactionId}`,
      type: "success",
    });
    const now = getUnixTime(Date.now());
    window.tvWidget?.activeChart?.()?.createShape(
      {
        time: now,
      },
      {
        shape: "vertical_line",
        text: `Manual trade #${response.transactionId}`,
        overrides: {
          linecolor: contractType === "PUT" ? "#F77B72" : "#3FFE00",
          textcolor: contractType === "PUT" ? "#F77B72" : "#3FFE00",
          showLabel: true,
        },
      }
    );
  };

  const onHigherClick = async () => {
    setHigherOrderInPrgress(true);
    try {
      await placeOrder("CALL");
    } catch (e) {
      addUserLog({
        message: (e as Error).message,
        type: "error",
      });
    } finally {
      setHigherOrderInPrgress(false);
    }
  };

  const onLowerClick = async () => {
    setLowerOrderInPrgress(true);
    try {
      await placeOrder("PUT");
    } catch (e) {
      addUserLog({
        message: (e as Error).message,
        type: "error",
      });
    } finally {
      setLowerOrderInPrgress(false);
    }
  };

  return {
    higherOrderInProgress,
    onHigherClick,
    tradeAmount,
    onChangeTradeAmount,
    duration,
    onChangeDurationAmount,
    handleDurationClick,
    durationUnit,
    lowerOrderInProgress,
    onLowerClick,
    durationAnchor,
    handleDurationMenuClose,
    handleDurationMenuItemClick,
  };
};

export default useChartTrade;
