import styled from 'styled-components';

export const BacktestingRoot = styled.div`
`;

export const WrappedContainer = styled.div`
  height: calc(100vh - 129px);
  iframe {
    height: inherit !important;
  }
`;

export const SelectionContainerRoot = styled.div`
  margin: auto;
  text-align: center;
  width: 640px;
  margin-bottom: -39px;
  position: relative;
`;

export const AutocompleteRoot = styled.div`
  width: 170px;
  && {
    .MuiAutocomplete-root {
      .MuiFormControl-root {
        .MuiInputBase-root  {
          input {
            padding-bottom: 9px;
          }
        }
      }
    }
  }
`;