import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import React from 'react';
import { useAppContext } from '../App.context';
import { StyledDurationButton, StyledTradeButton, StyledTradeTextBox, TradePanelRoot } from './Chart.styles';
import useChartTrade from './Chart.trade.hooks';

interface IProps {
  disabled?: boolean;
}

const ChartTradeContainer: React.FC<IProps> = (props) => {
  const { userData } = useAppContext();
  const {
    higherOrderInProgress,
    onHigherClick,
    tradeAmount,
    onChangeTradeAmount,
    duration,
    onChangeDurationAmount,
    handleDurationClick,
    durationUnit,
    lowerOrderInProgress,
    onLowerClick,
    durationAnchor,
    handleDurationMenuClose,
    handleDurationMenuItemClick,
  } = useChartTrade();

  return (
    <>
      <Tooltip title={props.disabled ? "Enabled after login" : ""}>
        <TradePanelRoot>
          <Grid container justifyContent="space-evenly" alignContent="center" direction="row">
            <Grid item>
              <Box color="success.main">
                <Tooltip title={!props.disabled && !higherOrderInProgress ? "Higher than entry price" : ""}>
                  <StyledTradeButton
                    color="inherit"
                    variant="outlined"
                    onClick={onHigherClick}
                    disabled={props.disabled || higherOrderInProgress}
                  >
                    <TrendingUpIcon fontSize="large" />
                  </StyledTradeButton>
                </Tooltip>
              </Box>
            </Grid>
            <Grid item>
              <StyledTradeTextBox
                label="Amount"
                value={tradeAmount}
                type="number"
                onChange={onChangeTradeAmount}
                disabled={props.disabled}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography variant="button" gutterBottom>
                        {userData?.accounts?.find(f => f.selected)?.currency}
                      </Typography>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item>
              <StyledTradeTextBox
                label="Duration"
                value={duration}
                type="number"
                onChange={onChangeDurationAmount}
                disabled={props.disabled}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <StyledDurationButton size="small" onClick={handleDurationClick} disabled={props.disabled}>
                        {durationUnit?.toUpperCase?.()}
                      </StyledDurationButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item>
              <Box color="error.main">
                <Tooltip title={!props.disabled && !lowerOrderInProgress ? "Lower than entry price" : ""}>
                  <StyledTradeButton
                    variant="outlined"
                    color="inherit"
                    onClick={onLowerClick}
                    disabled={props.disabled || lowerOrderInProgress}
                  >
                    <TrendingDownIcon fontSize="large" />
                  </StyledTradeButton>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </TradePanelRoot>
      </Tooltip>
      <Menu
        anchorEl={durationAnchor}
        keepMounted
        open={Boolean(durationAnchor)}
        onClose={handleDurationMenuClose}
      >
        <MenuItem onClick={handleDurationMenuItemClick('t')}>Tick(s)</MenuItem>
        <MenuItem onClick={handleDurationMenuItemClick('s')}>Second(s)</MenuItem>
        <MenuItem onClick={handleDurationMenuItemClick('m')}>Minute(s)</MenuItem>
        <MenuItem onClick={handleDurationMenuItemClick('h')}>Hour(s)</MenuItem>
        <MenuItem onClick={handleDurationMenuItemClick('d')}>Day(s)</MenuItem>
      </Menu>
    </>
  );
};

export default ChartTradeContainer;
