import { load, save, searchKey } from '@root/services/StorageSvc';
import React, { useEffect } from 'react';
import { useAppContext } from '../App.context';
import Datafeed from './Chart.datafeed';
import { WrappedContainer } from './Chart.styles';
import ChartTradeContainer from './Chart.trade.container';

interface IProps {
  render?: boolean;
}

const ChartContainer: React.FC<IProps> = (props) => {
  const { userData } = useAppContext();

  useEffect(() => {
    if (window.TradingView) {
      // window.tvWidget is required by TV framework
      // @ts-ignore
      window.tvWidget = new window.TradingView.widget({
        symbol: 'R_25', // default symbol
        interval: '1', // default interval
        fullscreen: true, // displays the chart in the fullscreen mode
        container: 'chart_container',
        datafeed: Datafeed,
        library_path: `${process.env.PUBLIC_URL}/charting_library/`,
        autosize: true,
        disabled_features: ['header_compare', 'create_volume_indicator_by_default'],
        theme: 'Light',
        custom_css_url: `${process.env.PUBLIC_URL}/charting_library.css`,
        studies_access: {
          type: 'black',
          tools: [{
            name: 'Accumulation/Distribution'
          }, {
            name: 'Chaikin Oscillator'
          }, {
            name: 'Chaikin Volatility'
          }, {
            name: 'Correlation - Log'
          }, {
            name: 'Correlation Coefficient'
          }, {
            name: 'Ease Of Movement'
          }, {
            name: 'Elder\'s Force Index'
          }, {
            name: 'Envelopes'
          }, {
            name: 'Klinger Oscillator'
          }, {
            name: 'Money Flow Index'
          }, {
            name: 'Net Volume'
          }, {
            name: 'On Balance Volume'
          }, {
            name: 'Price Volume Trend'
          }, {
            name: 'Ratio'
          }, {
            name: 'Spread'
          }, {
            name: 'Volume'
          }, {
            name: 'Volume Oscillator'
          }, {
            name: 'VWAp'
          }, {
            name: 'VWMA'
          }, {
            name: 'Zig Zag'
          }, {
            name: 'Volume Profile Visible Range'
          }, {
            name: 'VWAP'
          }, {
            name: 'Chaikin Money Flow'
          }],
        },
      });
      window.tvWidget?.onChartReady(() => {
        const key = 'tradingViewState';
        const state = load<{ state: object }>(key);
        if (state?.state) {
          setTimeout(() => {
            window.tvWidget?.activeChart?.()?.dataReady(() =>
              window.tvWidget?.load(state?.state)
            );
            // Without this, it throws error
          }, 0);
        }
        window.tvWidget?.subscribe?.('onAutoSaveNeeded', () => {
          window.tvWidget?.save(state => {
            save(key, { state });
          });
        });
      });
    }
  }, []);
  return (
    <div hidden={!props?.render}>
      <ChartTradeContainer disabled={!window.tvWidget || !userData?.isLoggedIn} />
      <WrappedContainer id="chart_container">
      </WrappedContainer>
    </div>
  );
};

export default ChartContainer;
