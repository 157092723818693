import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';

export const WrappedContainer = styled.div`
  height: calc(100vh - 115px);
  iframe {
    height: inherit !important;
  }
`;

export const TradePanelRoot = styled.div`
  margin: auto;
  text-align: center;
  position: absolute;
  top: 35px;
  left: 700px;
`;

export const StyledTradeTextBox = styled(TextField)`
  width: 80px;
  && {
    margin-left: 10px;
    margin-right: 10px;
  }
  input {
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    -moz-appearance: textfield;
  }
`;

export const StyledTradeButton = styled(Button)`
  && {
    height: 48px;
    border-radius: 0px;
  }
`;

export const StyledDurationButton = styled(Button)`
  && {
    min-width: 0px;
    font-size: inherit;
  }
`;
