import getUnixTime from "date-fns/getUnixTime";
import fromUnixTime from "date-fns/fromUnixTime";
import { ITradeHistoryData, ITradeHistoryRequest, TDirection } from "../BrokerSvc.types";
import { IOnMessage } from "./Deriv";
import waitForReadyConnection from "./WaitForConnection";

const getTradeHistory = (request: ITradeHistoryRequest): Promise<ITradeHistoryData[]> =>
  waitForReadyConnection().then((wsp) =>
    wsp
      ?.sendRequest({
        profit_table: 1,
        offset: request?.offset || 0,
        limit: request?.limit || 50,
        date_to: getUnixTime(request?.dateTo),
        description: 1,
      })
      .then((message: IOnMessage) => {
        if (message.error) {
          throw new Error(message?.error?.message);
        } else {
          return (
            message.profit_table?.transactions?.map((m) => ({
              date: fromUnixTime(m.purchase_time),
              symbol: m.shortcode.substring(
                m.shortcode?.indexOf("_") + 1,
                m.shortcode?.indexOf(`_${m.payout}`)
              ),
              ref: m.transaction_id.toString(),
              direction: m.shortcode?.startsWith("CALL") ? "up" : "down" as TDirection,
              profitLoss: +(m.sell_price - m.buy_price).toPrecision(2),
            })) || []
          );
        }
      })
  );

export default getTradeHistory;
