import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React, { useState } from 'react';
import LogsContainer from './Logs.container';
import TradesContainer from './Trades.container';
import { TabsRoot, WrappedContainer } from './TradesLogs.styles';

interface IProps {
  render?: boolean;
}

const TradesLogsContainer: React.FC<IProps> = props => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTabIndex(newValue);
  };
  return (
    <WrappedContainer hidden={!props?.render}>
      <Grid container>
        <Grid item xs={2}>
          <TabsRoot>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={selectedTabIndex}
              onChange={handleChange}
            >
              <Tab label="Trades" value={0} fullWidth />
              <Tab label="Logs" value={1} fullWidth />
            </Tabs>
          </TabsRoot>
        </Grid>
        <Grid item xs={10}>
          {selectedTabIndex === 0 && <TradesContainer />}
          {selectedTabIndex === 1 && <LogsContainer />}
        </Grid>
      </Grid>
    </WrappedContainer>
  );
};

export default TradesLogsContainer;
