import LinearProgress from '@material-ui/core/LinearProgress';
import styled from 'styled-components';

interface HorizontalBarRootProps {
  readonly height?: number | string;
}

// eslint-disable-next-line import/prefer-default-export
export const HorizontalBarRoot = styled(LinearProgress)<HorizontalBarRootProps>`
  && {
    height: ${({ height = 20 }) =>
      typeof height === 'string' ? height : `${height}px`};
    .MuiLinearProgress-bar {
      background-color: lightgrey;
    }
    &.MuiLinearProgress-colorPrimary {
      background-color: #f5f6fa;
    }
  }
`;
