import cloneDeep from "lodash/cloneDeep";
import logLevel from "loglevel";
import memoize from "memoizee";
import hash from "object-hash";
import {
  IDataSubscriptionRequest,
  IHistoricalDataRequest,
  IOHLCData,
} from "./BrokerSvc.types";
import * as derivBrokerInterface from "./deriv";

const logger = logLevel.getLogger("BrokerEngineInterface.ts");

const memoizeOptions = {
  maxAge: 24 * 60 * 60 * 1000,
  promise: true,
};

const loginCallbacks = new Map<string, () => void>();
const dataSubscriptionRequests = new Map<
  string,
  {
    serverSubscriptionId: string;
    clientCallbacks: {
      clientRequestId: string;
      clientRequest: IDataSubscriptionRequest;
    }[];
  }
>();

derivBrokerInterface.subscribeToWSOpen(() => {
  logger.debug("WS reconnected", dataSubscriptionRequests, loginCallbacks);
  /*
    1. resubscribe to all events
      - realtime data events for now
    2. In Chart.datafeed subscribe method, call onResetCacheNeededCallback to take care of scenarios where we might
    be disconnected for sometime and lost some of the data
  /*/
  const prevDataRequestMap = cloneDeep(dataSubscriptionRequests);
  dataSubscriptionRequests.clear();
  prevDataRequestMap.forEach((value) => {
    value.clientCallbacks?.forEach((eClient) => {
      // I am not updating the map with the new subscriptionId
      // Not needed now. Can revisit later, if needed
      logger.debug("Resubscribing: ", eClient);
      // Asks tradingview to reset cache
      eClient?.clientRequest?.onReset?.();
      // subscribe for candles again
      subscribeToOHLC(eClient.clientRequestId, eClient.clientRequest);
    });
  });

  loginCallbacks.forEach(async (callback, token) => {
    await derivBrokerInterface.login(token).then(callback);
  });
});

export const registerWSCloseListener = derivBrokerInterface.subscribeToWSClose;
export const registerLoginSuccessListener = (
  key: string,
  callback: () => void
) => loginCallbacks.set(key, callback);

export const getHistoricalData = async (request: IHistoricalDataRequest) => {
  const response = await derivBrokerInterface.getHistoricalData({
    start: request.start,
    symbol: request.symbol,
    timeFrame: request.timeFrame,
    end: request.end,
  });
  return response?.data?.map(({ open, high, low, close, epoch }) => ({
    time: epoch,
    open,
    high,
    low,
    close,
  }));
};

export const subscribeToOHLC = async (
  clientRequestId: string,
  clientRequest: IDataSubscriptionRequest
) => {
  const uniqueRequest = hash(clientRequest);
  if (dataSubscriptionRequests.get(uniqueRequest)) {
    dataSubscriptionRequests.get(uniqueRequest)?.clientCallbacks?.push({
      clientRequestId,
      clientRequest,
    });
  } else {
    // set this so that the object is ready for other calls to be registered
    // while we still wait for previous call to finish
    dataSubscriptionRequests.set(uniqueRequest, {
      clientCallbacks: [
        {
          clientRequestId,
          clientRequest,
        },
      ],
      serverSubscriptionId: "",
    });
    const subscriptionId = await derivBrokerInterface.subscribeToOHLC({
      symbol: clientRequest.symbol,
      timeFrame: clientRequest.timeFrame,
      onCandle: (candle: IOHLCData) =>
        dataSubscriptionRequests
          .get(uniqueRequest)
          ?.clientCallbacks?.forEach((e) => e.clientRequest.onCandle?.(candle)),
    });
    dataSubscriptionRequests.get(uniqueRequest)!.serverSubscriptionId =
      subscriptionId;
  }
};

export const unsubscribeFromOHLC = async (
  clientRequestId: string,
  originalSubscriptionRequest: IDataSubscriptionRequest
) => {
  const uniqueRequest = hash(originalSubscriptionRequest);
  const ref = dataSubscriptionRequests.get(uniqueRequest);
  const index = ref?.clientCallbacks?.findIndex(
    (f) => f.clientRequestId === clientRequestId
  );
  if (typeof index !== "undefined" && index >= 0) {
    ref?.clientCallbacks?.splice(index, 1);
    // If thre are no more clientCallbacks and we did get respones for our first request,
    // then unsubscribe from broker
    const shouldUnSubscribeFromBroker =
      ref?.clientCallbacks?.length === 0 && ref?.serverSubscriptionId;
    if (shouldUnSubscribeFromBroker) {
      dataSubscriptionRequests.delete(uniqueRequest);
      derivBrokerInterface.unsubscribeFromOHLC(ref?.serverSubscriptionId!);
    }
  }
};

export const getSupportedResolutions = memoize(
  derivBrokerInterface.getSupportedResolutions,
  memoizeOptions
);

export const getAllSymbols = memoize(
  derivBrokerInterface.getAllSymbols,
  memoizeOptions
);

export const getTradingTimes = memoize(
  derivBrokerInterface.getTradingTimes,
  memoizeOptions
);

export const login = derivBrokerInterface.login;
export const logout = derivBrokerInterface.logout;
export const getServerTime = derivBrokerInterface.getServerTime;
export const getTradeHistory = derivBrokerInterface.getTradeHistory;
export const buyContract = derivBrokerInterface.buyContract;
