import Grid from '@material-ui/core/Grid';
import { useMonaco } from "@monaco-editor/react";
import React, { useEffect } from 'react';
import tsc from 'typescript';
import EditorHeaderComponent from './Editor.header.component';
import FileExplorerContainer from './FileExplorer.container';
import { FullHeightGridContainer, StyledEditor, WrappedContainer } from './Strategy.styles';

const files = Array(20).fill(null).map((f, index) => ({
  name: `File${index}.ts`,
  allowDelete: index % 2 === 0,
  started: index % 2 === 0,
}));

interface IProps {
  render?: boolean;
}

const StrategyContainer: React.FC<IProps> = (props) => {
  const monaco = useMonaco();

  useEffect(() => {
    if (monaco) {
      console.log("here is the monaco isntance:", monaco);
      monaco.languages.typescript.typescriptDefaults.addExtraLib(
        `declare module '@bot/indicator' {
          export enum Indicators {
            SMA,
            WMA,
            EMA,
          }
          
          interface Indicator {
            name: Indicators;
            options: {
              period: number;
            };
          }
        }`,
      );
      monaco.languages.typescript.typescriptDefaults.addExtraLib(
        `declare module '@bot/strategy' {
          export enum Timeframe {
            Tick,
            M1,
            M2,
            M3,
            M5,
            M10,
            M15,
            M30,
            H1,
            H2,
            H4,
            H8,
            Day,
          }
          
          export interface TickData {}
          
          export interface CandleData {}
          
          export interface TradeData {}
          
          export abstract class Strategy {
            public addIndicator(indicator: Indicator) {}
            public addSymbol(symbol: string) {}
            public addTimeframe(timeframe: Timeframe) {};
            public onTick: (data: TickData) => void;
            public onCandle: (data: CandleData) => void;
            public onTrade: (data: TradeData) => void;
          }
        }`,
      );
    }
  }, [monaco]);

  return (
    <WrappedContainer hidden={!props?.render}>
      <Grid container>
        <Grid item xs={3}>
          <FileExplorerContainer files={files} />
        </Grid>
        <Grid item xs={9}>
          <FullHeightGridContainer container>
            <Grid item xs={12}>
              <EditorHeaderComponent name="File01.ts" />
            </Grid>
            <Grid item xs={12}>
              <StyledEditor
                defaultLanguage="typescript"
                defaultValue="// some comment"
                onChange={(value, event) => {
                  console.log("here is the current model value:", value);
                  console.log(tsc.transpile(value!));
                }}
              />
            </Grid>
          </FullHeightGridContainer>
        </Grid>
      </Grid>
    </WrappedContainer>
  );
};

export default StrategyContainer;
