import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Alert, { Color } from "@material-ui/lab/Alert";
import { CustomContentProps } from 'notistack';
import { forwardRef } from 'react';
import { TMessageType } from '../App';

export const CloseComponent: React.FC<{ onClick?: () => void }> = props => (
  <IconButton
    color="inherit"
    size="small"
    onClick={props.onClick}
  >
    <CloseIcon fontSize="inherit" />
  </IconButton>
);

const getServerity = (variant: TMessageType | 'default') =>
  variant === 'default' ? 'info' : variant as Color;

const ToastComponent: React.FC<CustomContentProps> = forwardRef((props, ref) => (
  <Alert
    ref={ref}
    action={(props?.action as Function)?.(props.id)}
    severity={getServerity(props.variant)}
  >
    {props.message}
  </Alert>
));

export default ToastComponent;
