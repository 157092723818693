import { createContext, useContext, useState } from "react";
import { IUserData } from "./App";

export enum Tabs {
  Chart,
  Strategy,
  Backtest,
  TradesLogs,
}

export type TAppContextModel = ReturnType<typeof useAppContextModel>;

const AppContext = createContext<TAppContextModel>({
  userData: {},
  setUserData: () => {},
  selectedTab: Tabs.Chart,
  setSelectedTab: () => {},
});
const { Provider } = AppContext;

export const useAppContext = () => useContext(AppContext);

export const useAppContextModel = () => {
  const [userData, setUserData] = useState<IUserData>();
  const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.Chart);
  
  return {
    userData,
    setUserData,
    selectedTab,
    setSelectedTab,
  };
};

export const AppContextProvider = Provider;
