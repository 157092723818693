import { IOnMessage } from "./Deriv";
import waitForReadyConnection from "./WaitForConnection";

const logout = () =>
  waitForReadyConnection().then((wsp) =>
    wsp
      ?.sendRequest({
        logout: 1,
      })
      .then((message: IOnMessage) => {
        if (message.error) {
          throw new Error(message?.error?.message);
        }
      })
  );

export default logout;
