import logLevel from "loglevel";

const originalFactory = logLevel.methodFactory;
logLevel.methodFactory = (methodName, logLevel, loggerName) => {
  const rawMethod = originalFactory(methodName, logLevel, loggerName);
  return (...args) =>
    rawMethod.apply(undefined, [`[${loggerName as string}]:`, ...args]);
};
logLevel.setLevel(logLevel.getLevel());

logLevel.setDefaultLevel(
  process.env.NODE_ENV === "development"
    ? logLevel.levels.DEBUG
    : logLevel.levels.ERROR
);
