import React from 'react';
import { EditorHeaderRootStyled } from './Strategy.styles';
import Typography from '@material-ui/core/Typography';

interface IProps {
  name: string;
}

const EditorHeaderComponent: React.FC<IProps> = props => {
  return (
    <EditorHeaderRootStyled>
      <Typography variant="h6">
        {props.name}
      </Typography>
    </EditorHeaderRootStyled>
  );
};

export default EditorHeaderComponent;
