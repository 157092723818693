import { subscribeToUserLog, unSubscribeFromUserLog } from '@root/services/UserLogSvc';
import { SnackbarKey, SnackbarProvider, useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import ToastComponent, { CloseComponent } from './Toast.component';

const ToastContainer: React.FC<{}> = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const onClickDismiss = (key: SnackbarKey) => () => closeSnackbar?.(key);

  useEffect(() => {
    const id = subscribeToUserLog((message) => enqueueSnackbar(message?.message, {
      variant: message.type,
      preventDuplicate: true,
      action: (key) => <CloseComponent onClick={onClickDismiss(key)} />,
    }));
    return () => {
      unSubscribeFromUserLog(id);
    };
  }, []);

  return null;
};

const ToastWithProvider: React.FC<{}> = () => (
  <SnackbarProvider
    maxSnack={5}
    autoHideDuration={5000}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    Components={{
      error: ToastComponent,
      warning: ToastComponent,
      info: ToastComponent,
      success: ToastComponent,
    }}
  >
    <ToastContainer />
  </SnackbarProvider>
);

export default ToastWithProvider;
