import styled from 'styled-components';
import Fab from '@material-ui/core/Fab';
import TableContainer from '@material-ui/core/TableContainer';

export const WrappedContainer = styled.div`
  height: calc(100vh - 115px);
  .MuiGrid-root {
    height: 100%;
  }
`;

export const StyledTableContainer = styled(TableContainer)`
  overflow: auto;
  height: 100%;
  &::-webkit-scrollbar {
    width: .5em;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(5, 81, 100, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
`;

export const TabsRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-right: 1px solid #E0E0E0;
  height: 100%;
`;

export const RefreshButton = styled(Fab)`
  && {
    position: absolute;
    right: 30px;
    bottom: 20px;
    z-Index: +1;
  }
`;
