import add from "date-fns/add";
import getUnixTime from "date-fns/getUnixTime";
import logLevel from "loglevel";
import { IDataSubscriptionRequest } from "../BrokerSvc.types";
import { IOnMessage } from "./Deriv";
import { subscribeToWSOpen } from "./SubscribeToWSCon";
import waitForReadyConnection from "./WaitForConnection";

const logger = logLevel.getLogger("BrokerEngineInterface.ts");
const subscription: Map<string, IDataSubscriptionRequest> = new Map();

const onMessage = (msgString: string) => {
  const message: IOnMessage = JSON.parse(msgString);
  switch (message.msg_type) {
    case "ohlc":
      const ohlc = {
        open: parseFloat(message?.ohlc?.open!),
        high: parseFloat(message?.ohlc?.high!),
        low: parseFloat(message?.ohlc?.low!),
        close: parseFloat(message?.ohlc?.close!),
        epoch: message?.ohlc?.open_time!,
      };
      const originalRequest = subscription.get(message?.subscription?.id!);
      logger.debug(
        "[wsp.onMessage.addListener]: ",
        ohlc,
        originalRequest
      );
      if (originalRequest) {
        originalRequest?.onCandle?.(ohlc);
      }
      break;
  }
};

const registerMessageListener = () =>
  waitForReadyConnection().then((wsp) => {
    wsp.onMessage.removeListener(onMessage);
    wsp.onMessage.addListener(onMessage);
  });
registerMessageListener();
subscribeToWSOpen(registerMessageListener);

export const subscribeToOHLC = async (request: Omit<IDataSubscriptionRequest, "onReset">) => {
  const count = 5;
  const start = getUnixTime(
    add(Date.now(), {
      seconds: -request.timeFrame * count,
    })
  );
  const wsp = await waitForReadyConnection();
  const message: IOnMessage = await wsp?.sendRequest({
    ticks_history: request.symbol,
    end: "latest",
    adjust_start_time: 1,
    granularity: request.timeFrame,
    count,
    start,
    style: "candles",
    subscribe: 1,
  });
  if (message.error) {
    throw new Error(message?.error?.message);
  }
  logger.debug("[SubscribeOHLC]", message?.subscription);
  subscription?.set(message?.subscription?.id!, request);
  return message?.subscription?.id!;
};

export const unsubscribeFromOHLC = async (subscriptionId: string) => {
  if (subscriptionId) {
    await waitForReadyConnection().then((wsp) =>
      wsp
        ?.sendRequest({
          forget: subscriptionId,
        })
        .then((message: IOnMessage) => {
          if (message.error) {
            throw new Error(message?.error?.message);
          }
        })
    );
    subscription?.delete(subscriptionId);
  }
};
