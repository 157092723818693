import React from 'react';
import { useEffect } from 'react';
import Datafeed from '../chart/Chart.datafeed';
import { BacktestingRoot, WrappedContainer } from './Backtest.styles';
import SelectionContainer from './Backtest.selection.container';

interface IProps {
  render?: boolean;
}

const ChartContainer: React.FC<IProps> = (props) => {
  useEffect(() => {
    if (window.TradingView) {
      // window.tvWidget = new (window.TradingView.widget({
      //   symbol: 'Bitfinex:BTC/USD', // default symbol
      //   interval: '1D', // default interval
      //   fullscreen: true, // displays the chart in the fullscreen mode
      //   container: 'backtest_chart_container',
      //   datafeed: Datafeed,
      //   library_path: `${process.env.PUBLIC_URL}/charting_library/`,
      //   autosize: true,
      //   disabled_features: [
      //     'header_compare',
      //     'timeframes_toolbar',
      //     'header_symbol_search',
      //     'header_resolutions',
      //     'symbol_search_hot_key',
      //     'display_market_status',
      //   ],
      // }));
    }
  }, []);
  return (
    <BacktestingRoot hidden={!props?.render}>
      <SelectionContainer />
      <WrappedContainer id="backtest_chart_container">
      </WrappedContainer>
    </BacktestingRoot>
  );
};

export default ChartContainer;
