import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import PausePresentationOutlinedIcon from '@material-ui/icons/PausePresentationOutlined';
import PlayArrowOutlinedIcon from '@material-ui/icons/PlayArrowOutlined';
import React, { SyntheticEvent } from 'react';
import CodeIcon from '@material-ui/icons/Code';
import { FileExplorerRowComponentRoot, StyledFileName } from './FileExplorer.styles';

interface IProps {
  name: string;
  allowDelete?: boolean;
  allowStart?: boolean;
  started?: boolean;
  selected?: boolean;
  onToggleStartStop?: () => void;
  onDelete?: () => void;
  onSelect?: () => void;
  onRename?: () => void;
}

const FileExplorerRowComponent: React.FC<IProps> = props => {
  const onToggleStartStopWrapper = (evt: SyntheticEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
    props?.onToggleStartStop?.();
  };
  const onDeleteWrapper = (evt: SyntheticEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
    props?.onDelete?.();
  };
  const onRenameWrapper = (evt: SyntheticEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
    props?.onRename?.();
  };
  return (
    <FileExplorerRowComponentRoot elevation={props?.selected ? 2 : 0} square onClick={props.onSelect}>
      <Grid container>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={2}>
              <CodeIcon />
            </Grid>
            <Grid item xs={10}>
              <StyledFileName variant="subtitle1" noWrap>
                {props.name}
              </StyledFileName>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <ButtonGroup>
            <Tooltip title={props?.allowStart ? props?.started ? 'Start' : 'Stop' : ''}>
              <Button color="primary" size="small" disabled={!props?.allowStart} onClick={onToggleStartStopWrapper}>
                {props?.started ? <PlayArrowOutlinedIcon /> : <PausePresentationOutlinedIcon />}
              </Button>
            </Tooltip>
            <Tooltip title="Rename">
              <Button size="small" onClick={onRenameWrapper}>
                <CreateOutlinedIcon />
              </Button>
            </Tooltip>
            <Tooltip title={props?.allowDelete ? "Delete" : ""} color="secondary">
              <Button size="small" disabled={!props?.allowDelete} onClick={onDeleteWrapper}>
                <CloseOutlinedIcon />
              </Button>
            </Tooltip>
          </ButtonGroup>
        </Grid>
      </Grid>
    </FileExplorerRowComponentRoot>
  );
};

export default FileExplorerRowComponent;
