import { IMessage } from "@root/containers/App";
import { v4 } from "uuid";

const MAX = 200;

type TCallback = (message: IMessage) => void;

const subscription: Map<string, TCallback> = new Map();
const logs = new Array<IMessage>();

// start a timer to control the `logs` size in memory
setInterval(() => {
  while (logs?.length > MAX) {
    logs.pop();
  }
}, 5 * 60 * 1000);

export const subscribeToUserLog = (callback: TCallback) => {
  const id = v4();
  subscription.set(id, callback);
  return id;
};

export const unSubscribeFromUserLog = (key: string) => subscription.delete(key);

export const addUserLog = (message: Omit<IMessage, "id" | "date">) => {
  const newMessage = {
    id: v4(),
    date: new Date(),
    ...message,
  };
  logs.unshift(newMessage);
  // Notify all
  subscription?.forEach((callback) => callback(newMessage));
};

export interface IGetUserLogs {
  limit: number;
  offset: number;
}
export const getUserLogs = async (request: IGetUserLogs) =>
  logs?.slice(request.offset, request.offset + request.limit);
