import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import RefreshIcon from '@material-ui/icons/Refresh';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TableLoader from '@root/containers/loader';
import format from 'date-fns/format';
import range from 'lodash/range';
import React, { useEffect, useRef } from 'react';
import { Waypoint } from 'react-waypoint';
import useTradeHistory from './Trades.hooks';
import { RefreshButton, StyledTableContainer } from './TradesLogs.styles';

const TradesContainer: React.FC<{}> = () => {
  const { tradeHistory, onLoadMore, loading, onReset } = useTradeHistory();
  const tableRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    onReset?.();
  }, [onReset]);

  const onResetClick = () => {
    tableRef?.current?.scrollTo?.({
      top: 0,
      behavior: 'smooth',
    });
    onReset?.();
  };

  return (
    <>
      <RefreshButton color="primary" disabled={loading} onClick={onResetClick}>
        <RefreshIcon />
      </RefreshButton>
      <StyledTableContainer ref={tableRef}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Asset</TableCell>
              <TableCell>Ref.</TableCell>
              <TableCell>Direction</TableCell>
              <TableCell>Profit/Loss</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tradeHistory?.map((row) => (
              <TableRow key={row.ref}>
                <TableCell component="th" scope="row">
                  {format(row.date, 'MMM do yyyy HH:mm aa')}
                </TableCell>
                <TableCell>{row.assetName}</TableCell>
                <TableCell>{row.ref}</TableCell>
                <TableCell>
                  {row.direction === 'up' ? (
                    <Box color="success.main">
                      <TrendingUpIcon />
                    </Box>) : (
                    <Box color="error.main">
                      <TrendingDownIcon />
                    </Box>)}
                </TableCell>
                <TableCell>{row.profitLoss}</TableCell>
              </TableRow>
            ))}
            {loading && range(10).map((val) => (
              <TableRow key={val}>
                <TableCell>
                  <TableLoader margin={0} />
                </TableCell>
                <TableCell>
                  <TableLoader margin={0} />
                </TableCell>
                <TableCell>
                  <TableLoader margin={0} />
                </TableCell>
                <TableCell>
                  <TableLoader margin={0} />
                </TableCell>
                <TableCell>
                  <TableLoader margin={0} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Waypoint onEnter={onLoadMore} />
      </StyledTableContainer>
    </>
  );
};

export default TradesContainer;
