import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Link from '@material-ui/core/Link';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tabs from '@material-ui/core/Tabs';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import QueryBuilderOutlinedIcon from '@material-ui/icons/QueryBuilderOutlined';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import WbIncandescentOutlinedIcon from '@material-ui/icons/WbIncandescentOutlined';
import React from 'react';
import { useAppContext } from '../App.context';
import DiscordImg from './Discord.svg';
import useHeaderHook from './Header.hooks';
import { ButtonWrapper, NotLoggedInTooltip, StyledTab } from './Header.styles';
import TelegramImg from './Telegram.svg';

const HeaderContainer = () => {
  const { selectedTab, userData } = useAppContext();
  const {
    handleTabChange,
    handleAccountClick,
    anchorEl,
    handleMenuClose,
    onLogout,
  } = useHeaderHook();
  const isLoggedIn = !!userData?.isLoggedIn;
  return (
    <>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <StyledTab icon={<EqualizerIcon fontSize="medium" />} label="Chart" />
        <StyledTab icon={<WbIncandescentOutlinedIcon fontSize="medium" />} label="Strategies" disabled={!isLoggedIn} />
        <StyledTab icon={<SchoolOutlinedIcon fontSize="medium" />} label="Backtest" disabled={!isLoggedIn} />
        <StyledTab icon={<QueryBuilderOutlinedIcon fontSize="medium" />} label="Trades/Logs" disabled={!isLoggedIn} />
      </Tabs>
      <ButtonWrapper>
        <ButtonGroup variant="outlined">
          <Button
            size="small"
            href={process.env.REACT_APP_DISCORD_INVITE_URL}
            title="Join us @ Discord" target="__blank" component={Link}>
            <img src={DiscordImg} alt="Discord" width="20px" height="20px" />
          </Button>
          <Button
            size="small"
            href={process.env.REACT_APP_TELEGRAM_CHAT_URL}
            title="Chat @ Telegram" target="__blank" component={Link}>
            <img src={TelegramImg} alt="Telegram" width="20px" height="20px" />
          </Button>
        </ButtonGroup>
        {!isLoggedIn && <Button
          variant="outlined"
          color="primary"
          onClick={() => window.location.assign(process.env.REACT_APP_OAUTH_URL!)}>
          Login
        </Button>}
        {isLoggedIn && <Button
          variant="text"
          color="primary"
          onClick={handleAccountClick}
          endIcon={<KeyboardArrowDownIcon />}>
          {userData?.accounts?.find?.(f => f.selected)?.acctNumber?.toUpperCase?.()}
        </Button>}
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {userData?.accounts?.map?.(acc => <MenuItem key={acc?.acctNumber}
            onClick={handleMenuClose}>{acc?.acctNumber?.toUpperCase?.()}</MenuItem>)}
          <MenuItem onClick={onLogout}>Logout</MenuItem>
        </Menu>
      </ButtonWrapper>
      {!isLoggedIn && <NotLoggedInTooltip title="Enabled after login">
        <div />
      </NotLoggedInTooltip>}
    </>
  );
};

export default HeaderContainer;
