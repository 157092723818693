import { TDay } from "@root/services/broker/BrokerSvc.types";

export const DEFAULT_SESSION = "24x7";

const sessionBuilder = (openTime: string, closeTime: string, days: TDay[]) => {
  const extractTime = (val: string) =>
    val.substring?.(0, val.lastIndexOf(":")).replace?.(":", "");
  return days?.length === 7
    ? DEFAULT_SESSION
    : days
        ?.map((day) => {
          let sessionDay = "";
          switch (day) {
            case "Sun":
              sessionDay = `${extractTime(openTime)}-${extractTime(closeTime)}:1`;
              break;
            case "Mon":
              sessionDay = `${extractTime(openTime)}-${extractTime(closeTime)}:2`;
              break;
            case "Tue":
              sessionDay = `${extractTime(openTime)}-${extractTime(closeTime)}:3`;
              break;
            case "Wed":
              sessionDay = `${extractTime(openTime)}-${extractTime(closeTime)}:4`;
              break;
            case "Thu":
              sessionDay = `${extractTime(openTime)}-${extractTime(closeTime)}:5`;
              break;
            case "Fri":
              sessionDay = `${extractTime(openTime)}-${extractTime(closeTime)}:6`;
              break;
            case "Sat":
              sessionDay = `${extractTime(openTime)}-${extractTime(closeTime)}:7`;
              break;
          }
          return sessionDay;
        })
        ?.join("|") || DEFAULT_SESSION;
};

export default sessionBuilder;
