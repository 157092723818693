import * as brokerEngineInterface from "@root/services/broker/BrokerSvc";
import { load, remove, save } from "@root/services/StorageSvc";
import { addUserLog } from "@root/services/UserLogSvc";
import React, { useCallback, useEffect, useState } from "react";
import { IUserAccount } from "../App";
import { Tabs as TabsEnum, useAppContext } from "../App.context";

const OAUTH_STORAGE_KEY = "oAuthData";
const SELECTED_ACC_STORAGE_KEY = "selectedAcct";

const useHeaderHook = () => {
  const { setSelectedTab, setUserData } = useAppContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleAccountClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) =>
      setAnchorEl(event.currentTarget),
    [setAnchorEl]
  );
  const handleMenuClose = useCallback(() => setAnchorEl(null), [setAnchorEl]);
  const handleTabChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: number) =>
      setSelectedTab(newValue as TabsEnum),
    [setSelectedTab]
  );
  const onLogout = useCallback(() => {
    setUserData(() => ({
      accounts: [],
      isLoggedIn: false,
    }));
    handleMenuClose();
    remove(SELECTED_ACC_STORAGE_KEY);
    remove(OAUTH_STORAGE_KEY);
    brokerEngineInterface
      .logout()
      .then(() =>
        addUserLog({
          message: "Logged out",
          type: "info",
        })
      )
      .catch((error) =>
        addUserLog({
          message: error.message,
          type: "error",
        })
      );
  }, [setUserData, handleMenuClose]);

  useEffect(() => {
    if (window.location.search) {
      save<{ oAuthData: string }>(OAUTH_STORAGE_KEY, {
        oAuthData: window.location.search,
      });
    }
    const search = load<{ oAuthData: string }>(OAUTH_STORAGE_KEY)?.oAuthData;
    if (search) {
      const urlParams = new URLSearchParams(search);
      const params = Object.fromEntries(urlParams);
      const userAccounts: IUserAccount[] = [];
      let selectedAccount = load<{ selectedAccount?: string }>(
        SELECTED_ACC_STORAGE_KEY
      )?.selectedAccount;
      for (let index = 1; true; index++) {
        if (params[`acct${index}`]) {
          if (!selectedAccount && index === 1) {
            save<{ selectedAccount?: string }>(SELECTED_ACC_STORAGE_KEY, {
              selectedAccount: params[`acct${index}`],
            });
            selectedAccount = params[`acct${index}`];
          }

          userAccounts.push({
            acctNumber: params[`acct${index}`],
            currency: params[`cur${index}`],
            token: params[`token${index}`],
            selected: selectedAccount === params[`acct${index}`],
          });
        } else break;
      }
      window.history.replaceState({}, document.title, "/");
      const selectedAccountToken = userAccounts?.find?.(
        (f) => f.selected
      )?.token;
      if (selectedAccountToken) {
        brokerEngineInterface
          .login(selectedAccountToken)
          .then(() =>
            setUserData((oldUserData) => ({
              ...oldUserData,
              accounts: userAccounts,
              isLoggedIn: true,
            }))
          )
          .catch((error) =>
            addUserLog({
              message: error.message,
              type: "error",
            })
          );

        brokerEngineInterface.registerLoginSuccessListener(
          selectedAccountToken,
          () => {
            setUserData((oldUserData) => ({
              ...oldUserData,
              isLoggedIn: true,
            }));
            addUserLog({
              message: "Logged in",
              type: "info",
            });
          }
        );

        brokerEngineInterface.registerWSCloseListener(
          () => {
            setUserData((oldUserData) => ({
              ...oldUserData,
              isLoggedIn: false,
            }));
            addUserLog({
              message: "Logged out",
              type: "info",
            });
          }
        );
      }
    }
  }, [setUserData]);

  return {
    handleTabChange,
    handleAccountClick,
    anchorEl,
    handleMenuClose,
    onLogout,
  };
};

export default useHeaderHook;
