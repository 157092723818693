import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import React, { useEffect, useState } from 'react';
import FileExplorerRowComponent from './FileExplorer.row.component';
import { ButtonRoot, FileExplorerRoot, FileItemsRoot } from './FileExplorer.styles';

interface File {
  name: string;
  allowDelete?: boolean;
  started?: boolean;
}

interface IProps {
  files: File[];
  onSelect?: (file: File) => void;
  onDelete?: (file: File) => void;
  onNew?: () => void;
  onToggleStartStop?: (file: File) => void;
}

const FileExplorerContainer: React.FC<IProps> = props => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [disableDelete, setDisableDelete] = useState(false);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTabIndex(newValue);
    props?.onSelect?.(props?.files?.[newValue]);
  };

  useEffect(() => {
    const file = props?.files?.[selectedTabIndex];
    if (file) {
      setDisableDelete(() => !file?.allowDelete);
    }
  }, [selectedTabIndex, props]);

  const selectedFile = props?.files?.[selectedTabIndex];

  return (
    <FileExplorerRoot>
      <ButtonRoot>
        <Tooltip title="New" color="primary">
          <Button variant="outlined" size="small" onClick={props.onNew}>
            <AddBoxOutlinedIcon />
          </Button>
        </Tooltip>
      </ButtonRoot>
      <FileItemsRoot>
        {props?.files?.map((file, index) => (
          <FileExplorerRowComponent name={file.name} key={file.name} />
        ))}
      </FileItemsRoot>
    </FileExplorerRoot>
  );
};

export default FileExplorerContainer;
