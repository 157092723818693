import Editor from "@monaco-editor/react";
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

export const WrappedContainer = styled.div`
  height: calc(100vh - 115px);
`;

export const StyledEditor = styled(Editor)`
  height: calc(100vh - 160px);
`;

export const EditorHeaderRootStyled = styled(Paper)`
  && {
    background-color: #3385c0;
    color: white;
    padding-left: 5px;
    margin-bottom: 5px;
  }
`;

export const FullHeightGridContainer = styled(Grid)`
  height: 100%;
`;

