import { IOnMessage } from "./Deriv";
import waitForReadyConnection from "./WaitForConnection";

const login = (token: string) =>
  waitForReadyConnection().then((wsp) =>
    wsp
      ?.sendRequest({
        authorize: token,
        add_to_login_history: 1,
      })
      .then((message: IOnMessage) => {
        if (message.error) {
          throw new Error(message?.error?.message);
        }
      })
  );

export default login;
