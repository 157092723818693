import React from 'react';
import Box from '@material-ui/core/Box';
import range from 'lodash/range';
import { HorizontalBarRoot } from './Loader.styles';

interface OwnProps {
  count?: number;
  height?: number | string;
  width?: number | string;
  margin?: number | string;
  className?: string;
}

const HorizontalBarLoader: React.FC<OwnProps> = ({
  count = 1,
  height = 50,
  width = '100%',
  margin = 10,
  className,
}: OwnProps) => {
  return (
    <Box margin={margin} width={width} className={className}>
      {range(count).map((val) => (
        <Box key={val} mb={1}>
          <HorizontalBarRoot height={height} />
        </Box>
      ))}
    </Box>
  );
};

export default HorizontalBarLoader;
