import styled from 'styled-components';
import Tab from '@material-ui/core/Tab';
import Tooltip from '@material-ui/core/Tooltip';

export const ButtonWrapper = styled.div`
  position: absolute;
  top: 36px;
  right: 15px;
  display: flex;
  gap: 10px;
`;

export const StyledTab = styled(Tab)`
  && {
    :not(.Mui-selected, .Mui-disabled) {
      color: #4a4a4a;
    }
  }
`;

export const NotLoggedInTooltip = styled(Tooltip)`
  position: absolute;
  top: 10px;
  left: 180px;
  width: 470px;
  height: 80px;
`;