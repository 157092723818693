import { addUserLog } from "@root/services/UserLogSvc";
import debounce from "lodash/debounce";
import logLevel from "loglevel";
import WebSocketAsPromised from "websocket-as-promised";
import { callOpenCallbacks } from "./SubscribeToWSCon";

let pingPongHandler: NodeJS.Timeout;
let wsp: WebSocketAsPromised | undefined = undefined;

const logger = logLevel.getLogger("ConnectionInit.ts");
let connectionRetryCount = 0;

const reconnect = debounce(() => {
  try {
    wsp?.close();
    callOpenCallbacks?.();
  } catch (e) {}
  // reconnect after 5 seconds
  logger.debug("Reconnecting after 5 seconds");
  addUserLog({
    message: "Reconnecting after 5 seconds",
    type: "warning",
  });
  ++connectionRetryCount;
  init();
}, 5000);

const init = () => {
  if (process.env.REACT_APP_BROKER_URL) {
    wsp = new WebSocketAsPromised(process.env.REACT_APP_BROKER_URL, {
      packMessage: (data) => JSON.stringify(data),
      unpackMessage: (data) => JSON.parse(data as string),
      attachRequestId: (data, requestId) =>
        Object.assign(
          {
            passthrough: {
              ...data?.passthrough,
              id: requestId,
            },
          },
          data
        ), // attach requestId to message as `id` field
      extractRequestId: (data) => data && data?.passthrough?.id,
    });

    wsp
      .open()
      .then(async () => {
        // Forget all streams if registered in previous connection
        await wsp?.sendRequest({
          forget_all: [
            "balance",
            "candles",
            "proposal",
            "proposal_open_contract",
            "ticks",
            "transaction",
          ],
        });

        clearInterval(pingPongHandler);
        pingPongHandler = setInterval(() => {
          if (wsp?.isOpened) {
            wsp?.sendRequest({ ping: 1 });
          }
        }, 60000);

        // ignore if its first time
        if (connectionRetryCount > 0) {
          callOpenCallbacks?.();
        }
      })
      .catch((e) => {
        addUserLog({
          message: e.message,
          type: "error",
        });
        logger.error(e);
      });

    wsp.onError.addListener((err) => {
      // Called if at any point WebSocket API signals some kind of error.
      logger.debug("Connection error", err);
      reconnect();
    });

    wsp.onClose.addListener(() => {
      // Called when connection is closed (for whatever reason).
      logger.debug("Connection closed");
      reconnect();
    });
  } else {
    logger.error("process.env.REACT_APP_BROKER_URL missing");
  }
};

init();

export const getConnection = () => wsp;
