const APP_PREFIX = "binaryBots";
const SEPARATOR = "_";

export const save = <T>(key: string, data: T) => {
  localStorage.setItem(`${APP_PREFIX}${SEPARATOR}${key}`, JSON.stringify(data));
};

export const load = <T>(key: string) => {
  return JSON.parse(
    localStorage.getItem(`${APP_PREFIX}${SEPARATOR}${key}`) || "{}"
  ) as T;
};

export const remove = (key: string) => {
  localStorage.removeItem(`${APP_PREFIX}${SEPARATOR}${key}`);
};

export const searchKey = <T>(prefix: string) => {
  const keyValues = new Map<string, T>();
  const keys = Object.keys(localStorage);
  for (let key of keys) {
    if (key?.startsWith(`${APP_PREFIX}${SEPARATOR}${prefix}`)) {
      keyValues.set(
        key?.replace(`${APP_PREFIX}${SEPARATOR}`, ""),
        JSON.parse(localStorage.getItem(key) || "{}")
      );
    }
  }
  return keyValues;
};
